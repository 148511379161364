import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./components/Layout";
import Home from "./pages/Home";
import Characters from "./pages/Characters";
import Character from "./pages/Character";
import Starships from "./pages/Starships";
import Starship from "./pages/Starship";
import Films from "./pages/Films";
import Vehicles from "./pages/Vehicles";
import Planets from "./pages/Planets";
import Film from "./pages/Film";
import Vehicle from "./pages/Vehicle";
import Planet from "./pages/Planet";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/characters" element={<Characters/>} />
          <Route exact path="/characters/:id" element={<Character/>} />
          <Route exact path="/films" element={<Films/>} />
          <Route exact path="/films/:id" element={<Film/>} />
          <Route exact path="/starships" element={<Starships/>} />
          <Route exact path="/starships/:id" element={<Starship/>} />
          <Route exact path="/vehicles" element={<Vehicles/>} />
          <Route exact path="/vehicles/:id" element={<Vehicle/>} />
          <Route exact path="/planets" element={<Planets/>} />
          <Route exact path="/planets/:id" element={<Planet/>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
